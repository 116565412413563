/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.example-spacer {
    flex: 1 1 auto;
  }

.floating-action {
    position: fixed;
    right: 1em;
    bottom: 1em;
}